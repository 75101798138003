<template>
  <div class="container-top">
    <div class="pc" style="padding-bottom:40px">
      <div class="banner" :class="{'bg-dark': !detail.circle}">
        <div class="container">
          <div v-if="detail.circle" class="circle" :style="{background: detail.circle}"></div>
        </div>
        <div class="banner-content">
          <div class="col-5 flex-align">
            <div>
              <div class="h2">{{ detail.title }}</div>
              <div class="body0-medium gray3" style="font-weight:400;margin-top:12px">{{ detail.subTitle }}</div>
              <a :href="detail.mainButton.link" target="_blank">
              <button class="button" style="margin-top:32px;width:242px"
                      :class="`${detail.mainButton.class}`">{{ detail.mainButton.name }}</button>
              </a>
            </div>
          </div>
          <div class="col-7 flex" style="align-items: flex-end">
            <img :src="`/static/img/guide/${detail.img}.png`" :alt="detail.title">
          </div>
        </div>
      </div>
      <div class="container" style="margin-top:100px;text-align: center">
        <div class="subtitle1 main" style="margin-bottom:24px">플랫폼 서비스는 앱만 있으면 되는게 아닙니다.</div>
        <div class="body1 sub2">플랫폼 서비스를 관리할 수 있는 사이트와<br>
          파트너들이 본인의 상품과 정보를 운영할 수 있는<br>파트너 관리자 사이트가 있어야<br>제대로 된 비즈니스를 할 수 있습니다.</div>
        <img :src="img('img-solution-1@2x')" alt="플랫폼 구성" style="margin-top:60px;width:800px">

        <div class="subtitle1 main" style="margin:120px 0 24px 0">런치팩 플랫폼 운영 솔루션은 무엇인가요?</div>
        <div class="body1 sub2">플랫폼 관리자와 파트너 관리자 사이트 등<br>운영에 필요한 지표정산, 회원, 상품 관리 기능을 제공하여<br>
          서비스 런칭 후 성공적인 비즈니스 운영을 돕는 플랫폼 관리자 솔루션 입니다.</div>
        <img :src="img('img-solution-2@2x')" alt="관리자사이트 예시" style="width:1000px;margin-top:60px">
      </div>
      <div class="bg-ice" style="margin-top:120px">
        <div class="container">
          <div class="flex">
            <img :src="img('img-solution-3@2x')" alt="플랫폼 서비스 전용 솔루션이 필요한 이유" style="width:580px">
            <div>
              <div class="text-center">
                <div class="subtitle1 main">플랫폼 서비스 전용 솔루션이 필요한 이유</div>
                <div class="body1 sub2" style="margin-top:24px">플랫폼 서비스 창업을 준비하시는 분들이 항상 겪게되는 문제가 있습니다.<br>
                  처음 서비스를 기획할 때 생각했던 부분만으로는 실제로 서비스를 운영하고<br>
                  관리할 수 없다는 것입니다. 처음 기획한 내용은 빙산의 일각이고<br>
                  런칭을 위해 추가로 고려해야하는 사항은 끝도 없이 발생하게 됩니다.</div>
              </div>
              <div class="text-center" style="margin-top: 240px;color:white">
                <div class="subtitle1">런치팩 솔루션을 이용해야 하는 이유</div>
                <div class="body1" style="margin-top:40px">현재 시장을 선도하는 플랫폼 서비스들의 특징은<br>
                  전문 개발팀과 디자이너팀을 보유하고 있다는 것입니다.<br><br>

                  그만큼 플랫폼 서비스는 전문성이 중요한 분야이기 때문에<br>
                  런치팩을 통해 창업하는 것이 일반 개발자 채용보다<br>
                  훨씬 빠르고 정확하게 전문적인 서비스를 만드는 데 유리합니다.<br><br>

                  그동안 다양한 플랫폼 서비스를 운영한 노하우를 살려<br>
                  창업자가 미처 고려하지 못했던 고급 운영 기능들을<br>
                  풀스펙으로 제공해드립니다.
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <img :src="img('img-solution-wave@2x')" style="width:100%">

      <div class="container" style="margin-top:120px">
        <div class="subtitle1 main text-center">런치팩 플랫폼 운영 솔루션이 제공하는 기능</div>

        <div v-for="(pack,idx) in packs" :key="'pack-'+idx" class="pack">
          <div class="flex-center pack-title">
            <div class="lp-divider-gray1"></div>
            <div class="h5 main" style="padding:0 20px">{{ pack.title }} PACK</div>
            <div class="lp-divider-gray1"></div>
          </div>
          <div class="flex wrap">
            <div class="col-4 flex-align" v-for="(item,i) in pack.list" :key="'pack-item-'+i" style="margin-bottom:20px">
              <span class="check">
                <img style="width:16px;height:16px" class="svg-white" src="/static/icon/fi_check.svg">
              </span>
              <span class="body1 main">{{ item }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mobile">
      <div class="banner" :class="{'bg-dark': !detail.circle}">
        <div class="container">
          <div v-if="detail.circle" class="circle" :style="{background: detail.circle}"></div>
        </div>
        <div class="banner-content-mobile">
          <div class="h6">{{ detail.title }}</div>
          <div class="body4" style="margin-top:4px">{{ detail.subTitle }}</div>
          <a :href="detail.mainButton.link" target="_blank">
            <button class="button body5-medium is-primary" style="margin-top:16px;width:180px">{{ detail.mainButton.name }}</button>
          </a>
        </div>
      </div>
      <div class="container" style="margin-top:40px;text-align: center">
        <div class="body0-medium main" style="margin-bottom:16px">플랫폼 서비스는 앱만 있으면<br>되는게 아닙니다.</div>
        <div class="body4 sub2">플랫폼 서비스를 관리할 수 있는 사이트와<br>
          파트너들이 본인의 상품과 정보를 운영할 수 있는<br>파트너 관리자 사이트가 있어야<br>제대로 된 비즈니스를 할 수 있습니다.</div>
        <img :src="img('img-solution-1@2x')" alt="플랫폼 구성" style="margin-top:32px;width:100%">

        <div class="body0-medium main" style="margin:40px 0 16px 0">런치팩 플랫폼 운영 솔루션은<br>무엇인가요?</div>
        <div class="body4 sub2">플랫폼 관리자와 파트너 관리자 사이트 등<br>운영에 필요한 지표정산, 회원, 상품 관리 기능을 제공하여<br>
          서비스 런칭 후 성공적인 비즈니스 운영을 돕는<br>플랫폼 관리자 솔루션 입니다.</div>
        <img :src="img('img-solution-2@2x')" alt="관리자사이트 예시" style="width:1000px;margin-top:32px">
      </div>
      <div class="container" style="margin-top:60px">
        <div class="text-center">
          <div class="body0-medium main">플랫폼 서비스 전용 솔루션이<br>필요한 이유</div>
          <div class="body4 sub2" style="margin-top:16px">플랫폼 서비스 창업을 준비하시는 분들이 항상 겪게되는 문제가 있습니다.
            처음 서비스를 기획할 때 생각했던 부분만으로는 실제로 서비스를 운영하고
            관리할 수 없다는 것입니다. 처음 기획한 내용은 빙산의 일각이고
            런칭을 위해 추가로 고려해야하는 사항은 끝도 없이 발생하게 됩니다.</div>
        </div>
      </div>
      <div class="bg-ice" style="margin-top:40px;padding-bottom:24px">
        <div class="container">
          <img :src="img('img-solution-3@2x')" alt="플랫폼 서비스 전용 솔루션이 필요한 이유" style="width:100%">
          <div class="text-center" style="margin-top: 24px;color:white">
            <div class="body0-medium">런치팩 솔루션을 이용해야 하는 이유</div>
            <div class="body4" style="margin-top:24px;word-break:break-all">
              <p>현재 시장을 선도하는 플랫폼 서비스들의 특징은
                전문 개발팀과 디자이너팀을 보유하고 있다는 것입니다.</p>
              <p>그만큼 플랫폼 서비스는 전문성이 중요한 분야이기 때문에
                런치팩을 통해 창업하는 것이 일반 개발자 채용보다
                훨씬 빠르고 정확하게 전문적인 서비스를 만드는 데 유리합니다.</p>
              <p>
              그동안 다양한 플랫폼 서비스를 운영한 노하우를 살려
              창업자가 미처 고려하지 못했던 고급 운영 기능들을
              풀스펙으로 제공해드립니다.</p>
            </div>
          </div>
        </div>
      </div>
      <img :src="img('img-solution-wave@2x')" style="width:100%">

      <div class="container" style="margin-top:40px">
        <div class="body0-medium main text-center">런치팩 플랫폼 운영 솔루션이<br>제공하는 기능</div>

        <div v-for="(pack,idx) in packs" :key="'pack-'+idx" class="pack">
          <div class="flex-center" style="margin-top:32px">
            <div class="lp-divider-gray1"></div>
            <div class="h7 main" style="padding:0 20px">{{ pack.title }} PACK</div>
            <div class="lp-divider-gray1"></div>
          </div>
          <div class="box-pack">
            <div>
              <div class="flex-align" v-for="(item,i) in pack.list" :key="'pack-item-'+i" style="margin-bottom:12px">
                <span class="check-mobile">
                  <img style="width:14px;height:14px" class="svg-white" src="/static/icon/fi_check.svg">
                </span>
                <span class="body4 main">{{ item }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "SolutionInfo",
    created() {

    },
    data() {
      return {
        detail: {
          title: '플랫폼 운영 솔루션',
          subTitle: '플랫폼 운영에 필요한 통합 솔루션',
          img: 'img_page_api',
          mainButton: {
            name: '데모 사이트 바로가기',
            class: 'is-primary',
            link: 'https://demooffice.launchpack.co.kr/office/admin'
          }
        },
        packs: [
          {
            title: '기본',
            list: ['대쉬보드', '서비스 관리', '회원 관리', '파트너 관리', '상품 관리', '기본 게시판 관리', '주문전환 관리',
              '필터/정렬 관리', '지역/카테고리 관리', '후기/문의 관리', '고객센터 관리']
          },
          {
            title: '주문전환',
            list: ['플랫폼서비스의 판매 상품 커머스', '장소 예약 커머스', '입점형 파트너 정산 커머스', '디지털 컨텐츠 다운로드 커머스',
              '모임 신청 커머스', '행사 예매 커머스\n(좌석 지정 기능은 미포함)', '사용권 쿠폰 커머스']
          }
        ]
      }
    },
    methods: {
      img(img) {
        return `/static/img/solution/${img}.png`;
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'

  .bg-ice
    background linear-gradient(180deg, #fff, #dbdde5, #657090, #152457)

  .lp-divider-gray1
    flex 1

  .pack
    margin auto

  .box-pack
    display flex
    justify-content center
    margin-top 40px
    div
      width 320px
      white-space pre-line

  .check
  .check-mobile
    width 20px
    height 20px
    background $primary
    color white
    border-radius 100%
    line-height 20px
    font-size 15px
    text-align center
    margin-right 12px
    display flex
    justify-content center
    align-items center

  .check-mobile
    width 18px
    height 18px
    line-height 18px
    font-size 14px
    margin-right 8px

  .banner
    background-color $gray4
    height 440px
    position relative
    overflow hidden
    color $main
  .banner-content
    position absolute
    width $page-width
    left 50%
    transform translateX(-50%)
    display flex
    height 100%
    z-index 1
  .circle
    opacity 0.15
    filter blur(300px)
    -webkit-backdrop-filter blur(300px)
    backdrop-filter blur(300px)
    position absolute
    top -50px
    left -603px
    width $page-width
    height $page-width

  .bg-dark
    background linear-gradient(132.62deg, #242428 34.49%, #4F4F4F 88.8%)
    color white

  .pack-title
    margin 80px 0 40px 0

  @media (max-width:1024px)
    .banner
      height 160px
      padding 0
      position relative
    .banner-content-mobile
      display block
      text-align center
      width 100%
      top 50%
      left 50%
      position absolute
      transform translate(-50%, -50%)
    .circle
      width 370px
      height 370px
      top -44px
      left -50px
    p
      margin-bottom 12px
</style>
